<template>
  <div class="card-sections-block-header-tmk">

    <el-row type="flex" justify="space-between" style="margin-bottom: 20px">
      <div style="font-size: 24px">
        Карта КПЭ <span v-if="card.info && card.info.closed_route">(Закрытый маршрут)</span>
      </div>
      <div v-if="$canAndRulesPass(user, 'card.dates_see')">
        <span style="white-space: nowrap; display: inline-block;margin-right: 5px">
          Период премирования: &nbsp;
          <click-to-edit
            v-model="card.info.date_start"
            type="date-picker"
            :disabled="!$canAndRulesPass(card, 'card.dates_update')"
            style="display: inline-block; margin-right: 5px"
            placeholder="Выберите дату начала"
            @input="infoFieldChanged('date_start')"
          ></click-to-edit>
          -
          <click-to-edit
            v-model="card.info.date_end"
            type="date-picker"
            :disabled="!$canAndRulesPass(card, 'card.dates_update')"
            style="display: inline-block;"
            placeholder="Выберите дату завершения"
            @input="infoFieldChanged('date_end')"
          ></click-to-edit>
        </span>

      </div>
      <div>
        <div
          v-if="(currentStage && currentStage.type === $constants.stage.types.TYPE_ASSESSMENT) || card.status === $constants.card.statuses.STATUS_COMPLETED"
          style="display: inline-block; margin-left:35px"
        >
          Итоговая оценка: {{card.bonus|numberFormat}}
        </div>
        <click-to-edit
          v-if="card.info && $canAndRulesPass(card, 'card.set_bonus_while_functional')"
          v-model="card.info.final_estimate"
          :number-format="{}"
          style="display: inline-block; margin-left:35px"
          placeholder="Введите итоговую оценку"
          @input="changeBonus"
        ></click-to-edit>
        <history-modal
          v-if="$canAndRulesPass(card, 'card.see_history')"
          style="display: inline-block; margin-left:35px"
          title="История изменений карты"
          width="70%"
          show-model-full-name
          :method="$api.card.getCardHistory"
          :params="{  id: card.id }"
        >
          <el-button
            slot="reference"
            type="text"
            size="mini"
            icon="fas fa-history"
          >
            История изменений
          </el-button>
        </history-modal>

        <el-button
          v-if="$canAndRulesPass(card, $permissions.CARD.EXPORT)"
          style="margin-left:35px"
          type="text"
          size="mini"
          icon="fas fa-file-excel"
          :loading="loadingDownloader"
          @click="exportToExcel"
        >
          Экспорт в XLSX
        </el-button>


      </div>
    </el-row>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import HistoryModal from "@/components/HistoryModal.vue";
import {downloader} from "@/mixins/downloader";
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import {cardInfoField} from "@/mixins/cardInfoField";

export default {
  name: "card-sections-block-header",
  mixins: [downloader, cardInfoField],
  components: {ClickToEdit, HistoryModal},
  props: {},

  computed: {
    ...mapGetters(['user']),
    ...mapGetters('card', {
      card:'card'
    }),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
    }),
    ...mapGetters('card/sections', {
      sectionsElements: 'sectionsElements'
    }),
  },
  watch: {},
  data() {
    return {
      loadingNotify: false,
    }
  },
  methods: {
    changeBonus(){
      this.infoFieldChanged('final_estimate')
        .then(()=>{
          this.$store.dispatch('card/loadCardData');
          this.$store.dispatch('card/stages/loadStages');
        })
    },
    exportToExcel() {
      this.downloadFile(
        'export.xlsx',
        this.$api.exportImport.export,
        {
          entity: 'card',
          data: {
            id: this.card.id
          }}
      )
      // this.downloadFile(
      //   false,
      //   this.$api.card.exportToXls,
      //   {id: this.card.id}
      // )
    },

  }
}
</script>


<style lang="scss" scoped>

</style>